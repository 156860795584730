import React, { Component } from 'react';
import Registration from './Registration';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { userPropTypes } from 'helpers/proptypes';
import { requestCurrentUser } from 'store/actions/accounts/user';
import { requestRecentRegistrations } from 'store/actions/registrations/';
import { Loading, Alert, PageHeader } from 'components';

export class Registrations extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    user: userPropTypes,
    recentRegistrations: PropTypes.array,
    requestCurrentUser: PropTypes.func.isRequired,
    requestRecentRegistrations: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.requestRecentRegistrations();
    this.props.requestCurrentUser();
  }

  render() {
    if (this.props.hasErrored) {
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          Something went wrong. Could not retrieve user or registration data.
        </Alert>
      );
    }

    if (this.props.isLoading) {
      return <Loading />;
    }
    return (
      <div className="white-soft-box2 white-soft-box2--no-animate">
        <PageHeader.Basic
          title="Registrations"
          actions={
            <Link
              to={window.URLS['accounts:registrations_archive']()}
              className="btn btn-primary pull-right"
            >
              <i className="fa fa-archive icon-space-r" />
              Archive
            </Link>
          }
        />
        {this.props.recentRegistrations.length ? (
          this.props.recentRegistrations.map((reg, index) => {
            return <Registration key={reg.id} reg={reg} user={this.props.user} />;
          })
        ) : (
          <div className="page-empty">
            <div className="page-empty__icon">
              <span className="fa fa-university" />
            </div>
            <div className="page-empty__title">No Registrations</div>
            <div className="page-empty__subtitle mt1">
              You can register for events on our <a href="/calendar/">calendar</a> or{' '}
              <a href="/events/explore/">explore upcoming events</a>.
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ accountsUser, registrations }) => {
  return {
    user: accountsUser[accountsUser.current],
    recentRegistrations: registrations.recentRegistrations,
    isLoading: accountsUser.isLoading || registrations.recentRegistrationsLoading,
    hasErrored: accountsUser.hasErrored || registrations.error,
  };
};

export default connect(
  mapStateToProps,
  { requestCurrentUser, requestRecentRegistrations }
)(Registrations);
